import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { IntlErrorCode, NextIntlClientProvider } from 'next-intl'
import { walsheim } from '@/lib/fonts'
import { useRouter } from 'next/router'

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  function onError(error: any) {
    // Source: https://next-intl-docs.vercel.app/docs/usage/configuration#error-handling
    if (error.code === IntlErrorCode.MISSING_MESSAGE) {
      // Missing translations are expected and should do nothing
    } else {
      // Other errors indicate a bug in the app and should be reported
      throw error
    }
  }

  return (
    <div className={walsheim.className}>
      <NextIntlClientProvider
        onError={onError}
        locale={router.locale}
        messages={pageProps.messages}
        timeZone="Europe/Berlin"
      >
        <Component {...pageProps} />
      </NextIntlClientProvider>
    </div>
  )
}
